<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="pa-5">

      <v-row>
        <v-col>
          <div class="mb-2">Meter Type</div>
          <v-btn-toggle v-model="form.meterType">
            <v-btn @click="setMeterTypeId(9)" value="9">
              <v-icon color="amber">mdi-lightbulb</v-icon>
            </v-btn>
            <v-btn @click="setMeterTypeId(10)" value="10">
              <v-icon color="red">mdi-fire</v-icon>
            </v-btn>
            <v-btn @click="setMeterTypeId(2705)" value="2705">
              <v-icon color="blue">mdi-water</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col>
          <SupplyNumber
            :type="form.meterType === '9' ? 'electricity' : form.meterType === '2705' ? 'water' : 'gas'"
            :supplyNumberReadOnly="true"
          />
          <span v-if="showSupplyNumberValidationError" class="mt-2">
            <span class="red--text"> Please enter full supply number </span>
          </span>

        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="form.loaId"
            :items="filteredLoas"
            label="Select LOA"
            prepend-icon="mdi-office-building-marker"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="form.supplierId"
            :items="filteredSuppliers"
            item-text="name"
            item-value="id"
            label="Select Supplier"
            prepend-icon="mdi-office-building-marker"
            @change="selectSupplier"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-1">
        <v-btn  color="accent" text  @click.stop="$emit('click-close')">
        Close
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          text
          :color="companyColor"
          :disabled="!selectedCompany || form.loaId === 0"
          @click="validateAndSubmitForm"
        >
          Send {{ formName }} to {{ selectedSupplier.generalEmail }}
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
// import dayjs from "dayjs"
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import { mapActions, mapGetters } from "vuex"
import SupplyNumber from "@/components/supply-number"
import {changesSaved, somethingWentWrong } from "@/helpers/utilities"
import api from "@/store/api"

export default {
  components: { SupplyNumber },
  props: {
    formName: {
      type: String,
      default: ""
    },
    companyColor: {
      type: String,
      default: "grey"
    },
    selectedCompany: {
      type: String
    },
    disabledSubmit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showSupplyNumberValidationError: false,
    selectedSupplierId: null,
    editable:true,
    // suppliers: [],
    valid: true,
    form: {
      meterType: "9",
      addressId: "",
      selectSupplier: "",
      loaId: 0,
      supplierId: ""
    },
    meterTypeId:null,
  }),
  watch: {
    supplyDetails: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if(
          ( this.form.meterType === '9' &&
            ( 
              newVal.sId !== "" &&
              newVal.mpIdA !== "" &&
              newVal.mpIdB !== "" &&
              newVal.cd !== ""
            )
          ) || 
          ( this.form.meterType !== '9' &&
            (
              newVal.supplyNumber !== "" &&
              newVal.electricSupplyNo !== ""
            )
          )
        ) {
          this.showSupplyNumberValidationError = false
          
        }
      },
      deep: true
      
    }
  },
  computed: {
    ...mapGetters("company", ["details", "companyId"]),
    ...mapGetters("forms", ["supplyDetails"]),
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("addresses", ["addressItems"]),
    ...mapGetters("contacts", ["selectedContact", "primaryContactFullName"]),
    ...mapGetters("supplier", ["suppliers", "suppliersItems", "selectedSupplier"]),
    ...mapGetters("loas", [ "loas"]),
    ...mapGetters("employee", ["userId", "userName", "employeeCampaign"]),
    fullName(){
      return `${this.selectedContact.title} ${this.selectedContact.firstName} ${this.selectedContact.lastName}`
    },
    filteredSuppliers() {
      if(this.form.meterType === "9") {
          return this.suppliersItems.filter(supplier => supplier.electricity === 1)
      }else if(this.form.meterType === "10") {
          return this.suppliersItems.filter(supplier => supplier.gas === 1)
      }else return this.suppliersItems
          
    },
    // filteredSuppliers() {
    //   const filteredSuppliersArray = this.suppliers.map(option => ({
    //     text: option.supplierName,
    //     value: option.supplierId
    //   }))

    //   return filteredSuppliersArray
    // },
    filteredLoas(){
      if(this.selectedCompany === "utility hive") {
        const filteredLOAArray = this.loas.filter(loa => loa.saleTypeId === 2)
        return filteredLOAArray.map((option) => ({
          text: option.loaText,
          value: option.loaId
        }))
      }
        const filteredLOAArray = this.loas.filter(loa => loa.saleTypeId === 1)
        return filteredLOAArray.map((option) => ({
            text: option.loaText,
            value: option.loaId
          }))
    },
  },
  mounted(){
    this.fetchLoas()
    this.fetchSuppliers(9)
  },
  methods: {
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("loas", ["fetchLoas"]),
    ...mapActions("supplier", ["fetchSuppliers", "selectSupplier"]),
    setMeterTypeId(id) {
      this.meterTypeId = id
      this.fetchSuppliers(this.meterTypeId)
    },
    async sendInfoRequestUH(){
      // const meterType = this.form.meterType === '9' ? 'Electricity' : 'Gas'
      // const requestDescription = `Info Request Sent<br/>Supplier: ${this.selectedSupplier.supplierName}<br/>Sent By: ${this.userName}<br/>LOA ID: ${this.this.form.loaId}<br/>`

      const supplyNumber = this.form.meterType === '9' ? ( this.supplyDetails.sId + this.supplyDetails.mpIdA + this.supplyDetails.mpIdB + this.supplyDetails.cd ) : this.supplyDetails.supplyNumber
      const requestDetails = {
        meterTypeId:this.form.meterType,
        companyName: this.details.companyName,
        companyId: this.details.companyId,
        loaId: this.form.loaId,
        employeeName: this.userName,
        supplierId: this.selectedSupplier.supplierId,
        supplierName: this.selectedSupplier.supplierName,
        supplyNumber: supplyNumber,
        employeeId: this.userId,
        contactId: this.selectedContact.contactId
        // description: requestDescription,

      }
      const response = await api.post('sendUHInfoRequest', requestDetails)
      if (response.status === 200){
        changesSaved(`Info Request Sent to ${this.selectedSupplier.supplierName}`)
        this.requestDetails = ''
        setTimeout(() => {
          this.fetchNotes()
        }, 1000)

      } else {
        somethingWentWrong()
      }
    },
    async sendInfoRequestCSS(){
      // const requestDescription = `Info Request Sent<br/>Supplier: ${this.selectedSupplier.supplierName}<br/>Sent By: ${this.userName}<br/>LOA ID: ${this.this.form.loaId}<br/>`
      const supplyNumber = this.form.meterType === '9' ? ( this.supplyDetails.sId + this.supplyDetails.mpIdA + this.supplyDetails.mpIdB + this.supplyDetails.cd ) : this.supplyDetails.supplyNumber
      const requestDetails = {
        meterTypeId:this.form.meterType,
        companyName: this.details.companyName,
        companyId: this.details.companyId,
        loaId: this.form.loaId,
        employeeName: this.userName,
        supplierId: this.selectedSupplier.supplierId,
        supplierName: this.selectedSupplier.supplierName,
        supplyNumber: supplyNumber,
        employeeId: this.userId,
        contactId: this.selectedContact.contactId
        // description: requestDescription,

      }
      const response = await api.post('sendCSSInfoRequest', requestDetails)
      if (response.status === 200){
        changesSaved(`Info Request Sent to ${this.selectedSupplier.supplierName}`)
        this.requestDetails = ''
        setTimeout(() => {
          this.fetchNotes()
        }, 1000)

      } else {
        somethingWentWrong()
      }
    },
    validateAndSubmitForm() {
      this.showSupplyNumberValidationError = false

      // this.supplyDetails.pc === "" ||
      // this.supplyDetails.mtc === "" ||
      // this.supplyDetails.llf === "" ||
      if(
          ( this.form.meterType === '9' &&
              ( 
                  Object.keys(this.supplyDetails).length === 0 || 
                  this.supplyDetails.sId === "" ||
                  this.supplyDetails.mpIdA === "" ||
                  this.supplyDetails.mpIdB === "" ||
                  this.supplyDetails.cd === ""
              )
          ) || 
          ( this.form.meterType !== '9' &&
              (
                  Object.keys(this.supplyDetails).length === 0 || 
                  this.supplyDetails.supplyNumber === "" ||
                  this.supplyDetails.electricSupplyNo === ""
              )
          )
      ) {
          this.showSupplyNumberValidationError = true
          return false
      }
      this.submitInfoRequest()
    },
    submitInfoRequest() {
        if(this.selectedCompany === 'utility hive'){
           this.sendInfoRequestUH()
        } else {
          this.sendInfoRequestCSS()
        }
    }
  },
  created() {
    if (this.formEditMode) {
      Object.entries(this.details).forEach(
        mergeExistingEntriesIntoForm(this.form)
      )
    }
  }
}
</script>
